import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import {
  getFirestore,
  collection,
  addDoc,
  getDoc,
  setDoc,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  PARENTID: "parentId"
};

const initialState = {
  parentId: ''
};


const reducer = (state, action) => {
  const { parentId } = action.payload;
  switch (action.type) {
    case ACTION.PARENTID:
      return {
        ...state,
        parentId
      }

    default:
      break;
  }
};

const RegistrationContext = createContext(initialState);

// ----------------------------------------------------------------------
RegistrationProvider.propTypes = {
  children: PropTypes.node,
};

function RegistrationProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const AUTH = getAuth(firebaseApp);
  const parentRef = collection(DB, 'parents');
  const studentsRef = collection(DB, 'students');

  const [state, dispatch] = useReducer(reducer, initialState);

  const register = async (data) => {
    const parentsData = {
      "parentFirstName1": data.parentFirstName1,
      "parentLastName1": data.parentLastName1,
      "phoneNumber1": data.phoneNumber1,
      "whatsPhoneNumber1": data.whatsPhoneNumber1,
      "address1": data.address1,
      "email1": data.email1,
      "parentFirstName2": data.parentFirstName2,
      "parentLastName2": data.parentLastName2,
      "phoneNumber2": data.phoneNumber2,
      "whatsPhoneNumber2": data.whatsPhoneNumber2,
      "address2": data.address2,
      "email2": data.email2,
      "childs": []
    };

    await addDoc(parentRef, parentsData).then(async (docRef) => {
      await updateDoc(docRef, { UID: docRef?.id });
      data.UID = docRef?.id;
    });
    dispatch({
      type: ACTION.PARENTID,
      payload: {
        parentId: data.UID,
      },
    });

  }

  const addStudent = async (data) => {
    let email = `${data.firstNameEn.replace(/\s/g, '')}${data.lastNameEn.replace(/\s/g, '')}@altawheedschool.ca`;
    data.parentId = state.parentId;
    const parentIdRef = doc(DB, 'parents', state.parentId);
    const parentIdSnap = await getDoc(parentIdRef);

    data.email = email;
    data.password = email;
    data.role = "student";
    data.photoURL = "";

    const userData = {
      email: data.email,
      role: data.role,
      photoURL: data.photoURL,
      firstName: data.firstName,
      lastName: data.lastName,
      isActif: false,
      isAdmin: false
    }

    createUserWithEmailAndPassword(AUTH, email, email).then(async (res) => {
      data.UID = res.user?.uid;
      userData.UID = res.user?.uid;
      const studentRef = doc(studentsRef, res.user?.uid);
      const userRef = doc(DB, 'users', res.user?.uid);
      await setDoc(studentRef, data);
      await setDoc(userRef, userData);
      await updateDoc(parentIdRef, { childs: [...parentIdSnap.data().childs, res.user?.uid] });
    }).catch(() => {
      email = `${data.firstNameEn.replace(/\s/g, '')}${data.lastNameEn.replace(/\s/g, '')}-${Math.floor(Math.random() * 100)}@altawheedschool.ca`;

      createUserWithEmailAndPassword(AUTH, email, email).then(async (res) => {
        data.UID = res.user?.uid;
        data.email = email;
        data.password = email;
        userData.UID = res.user?.uid;
        userData.email = email;
        const studentRef = doc(studentsRef, res.user?.uid);
        const userRef = doc(DB, 'users', res.user?.uid);
        await setDoc(studentRef, data);
        await setDoc(userRef, userData);
        await updateDoc(parentIdRef, { childs: [...parentIdSnap.data().childs, res.user?.uid] });
      })
    }
    )
  }

  const resetRegistration = () => {
    dispatch({
      type: ACTION.PARENTID,
      payload: {
        parentId: '',
      },
    })
  }

  return (
    <RegistrationContext.Provider
      value={{
        ...state,
        method: 'firebase',
        register,
        addStudent,
        resetRegistration
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}

export { RegistrationContext, RegistrationProvider };
