import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  setDoc,
  doc,
  getDocs,
} from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  LIST: 'homeworkList',
};

const initialState = {
  studentsList: [],
};

const reducer = (state, action) => {
  const { studentsList } = action.payload;
  switch (action.type) {
    case ACTION.LIST:
      return {
        ...state,
        studentsList
      };
    default:
      return state;
  }
};

const StudentContext = createContext(initialState);

// ----------------------------------------------------------------------

StudentProvider.propTypes = {
  children: PropTypes.node,
};

function StudentProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const [state, dispatch] = useReducer(reducer, initialState);
  const studentsRef = collection(DB, 'students');

  const getAllStudents = async (classe) => {
    const q = query(studentsRef,
      where('classe', '==', classe),
      where('year', '==', 2024),
      orderBy('firstName', 'asc'),
    );
    const newEntities = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      if (entity) newEntities.push(entity);
    });

    dispatch({
      type: ACTION.LIST,
      payload: {
        studentsList: newEntities,
      },
    });
  };

  return (
    <StudentContext.Provider
      value={{
        ...state,
        method: 'firebase',
        getAllStudents,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
}

export { StudentContext, StudentProvider };
