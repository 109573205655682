import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  orderBy,
  getDocs,
  setDoc,
  getDoc,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

import { useSnackbar } from 'notistack';
import i18n from '../locales/i18n';
import { fTimestamp } from '../utils/formatTime';

const ACTION = {
  LIST: 'homeworkList',
  ADD: 'addhomeworks',
  GET: 'getOneHomework',
};

const initialState = {
  homeworkList: [],
  currentHomework: null,
};

const reducer = (state, action) => {
  const { homeworkList, homework } = action.payload;
  switch (action.type) {
    case ACTION.LIST:
      return {
        ...state,
        homeworkList
      };
    case ACTION.ADD:
      return {
        ...state,
        homeworkList: [...state.homeworkList, homework],
      };
    case ACTION.GET:
      return {
        ...state,
        currentHomework: homework,
      };
    default:
      return state;
  }
};

const HomeworkContext = createContext(initialState);

// ----------------------------------------------------------------------

HomeworkProvider.propTypes = {
  children: PropTypes.node,
};

function HomeworkProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const [state, dispatch] = useReducer(reducer, initialState);
  const homeworkRef = collection(DB, 'homeworks');


  const getHomeworkClasse = async (classe) => {
    console.log(classe)
    const today = fTimestamp(new Date().setHours(0, 0, 0, 0));
    const q = query(homeworkRef,
      where('classe', '==', classe),
      where('publishDate', '<=', today),
      orderBy('publishDate', 'desc'),
      orderBy('classType', 'asc'),
    );
    const newEntities = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      if (entity) newEntities.push(entity);
    });
    console.log(newEntities)

    dispatch({
      type: ACTION.LIST,
      payload: {
        homeworkList: newEntities,
      },
    });
  };

  const getAllHomework = async (classe, classType) => {
    const q = query(homeworkRef,
      where('classe', '==', classe),
      where('classType', '==', classType),
      orderBy('publishDate', 'desc'),
    );
    const newEntities = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      if (entity) newEntities.push(entity);
    });

    dispatch({
      type: ACTION.LIST,
      payload: {
        homeworkList: newEntities,
      },
    });
  };

  const getHomework = async (UID) => {
    const docRef = doc(homeworkRef, UID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.GET,
        payload: {
          homework: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
    return docSnap.data();
  };

  const addHomework = async (data) => {
    const q = query(
      homeworkRef,
      where('publishDate', '==', data.publishDate),
      where('classe', '==', data.classe),
      where('classType', '==', data.classType),
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.docs.length > 0) {
      return 'error';
    }

    await addDoc(homeworkRef, data).then(async (docRef) => {
      await updateDoc(docRef, { UID: docRef?.id });
      data.UID = docRef?.id;
    });
    dispatch({
      type: ACTION.ADD,
      payload: {
        homework: data,
      },
    });
  };

  const editHomework = async (data) => {
    if (data.publishDate !== state.currentHomework.publishDate) {
      const q = query(
        homeworkRef,
        where('publishDate', '==', data.publishDate),
        where('classe', '==', data.classe),
        where('classType', '==', data.classType),
      );
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        return 'error';
      }
    }

    await setDoc(doc(homeworkRef, state.currentHomework.UID), data);
    const indexHomework = state.homeworkList.indexOf(state.homeworkList.find((homework) => homework.UID === state.currentHomework.UID));
    const newList = [...state.homeworkList];
    newList[indexHomework] = data;
    dispatch({
      type: ACTION.LIST,
      payload: {
        homeworkList: newList,
      },
    });
  };

  const deleteHomework = async (UID, homeworkList) => {
    await deleteDoc(doc(homeworkRef, UID)).then(() => {
      enqueueSnackbar(i18n.t('dashboard.homework.deletedSuccesful'));
      dispatch({
        type: ACTION.LIST,
        payload: {
          homeworkList,
        },
      });
    });
  };

  const deleteHomeworks = async (UIDs, homeworkList) => {
    UIDs.forEach((UID) => {
      deleteDoc(doc(homeworkRef, UID));
    })
    dispatch({
      type: ACTION.LIST,
      payload: {
        homeworkList,
      },
    });
  };

  return (
    <HomeworkContext.Provider
      value={{
        ...state,
        method: 'firebase',
        addHomework,
        getAllHomework,
        getHomework,
        deleteHomework,
        deleteHomeworks,
        editHomework,
        getHomeworkClasse
      }}
    >
      {children}
    </HomeworkContext.Provider>
  );
}

export { HomeworkContext, HomeworkProvider };
