function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  about: '/about-us',
  logo: '/logo',
  activities: '/activities',
  team: '/team',
  contact: '/contact-us',
  registration: '/registration',
  sendBlog: '/send-blog',
  page404: '/404',
  page500: '/500',
  UserBanned: '/banned',
  components: '/components',
  termsConditions: '/terms-conditions',
  registrationCompleted: '/registration-completed',
  home: '/'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  edition: {
    root: path(ROOTS_DASHBOARD, '/edition'),
    list: path(ROOTS_DASHBOARD, '/edition/list'),
    newEdition: path(ROOTS_DASHBOARD, '/edition/new-edition'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    students: path(ROOTS_DASHBOARD, '/user/students'),
    allstudents: path(ROOTS_DASHBOARD, '/user/studentsAll'),
    teachers: path(ROOTS_DASHBOARD, '/user/teachers'),
    parents: path(ROOTS_DASHBOARD, '/user/parents'),
    editStudent: path(ROOTS_DASHBOARD, '/user/student/:name/edit'),
    editTeacher: path(ROOTS_DASHBOARD, '/user/teacher/:name/edit'),
    editParent: path(ROOTS_DASHBOARD, '/user/parent/:name/edit'),
    viewParent: path(ROOTS_DASHBOARD, '/user/parent/'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    mystudents: path(ROOTS_DASHBOARD, '/user/mystudents'),
    // studentInfo: path(ROOTS_DASHBOARD, '/user/studentInfo/:UID'),
  },
  homeworks: {
    root: path(ROOTS_DASHBOARD, '/homeworks'),
    posts: path(ROOTS_DASHBOARD, '/homeworks/posts'),
    class: path(ROOTS_DASHBOARD, '/homeworks/class'),
    newPost: path(ROOTS_DASHBOARD, '/homeworks/new-homework'),
  },
  category: {
    root: path(ROOTS_DASHBOARD, '/category'),
    newCategory: path(ROOTS_DASHBOARD, '/category/new-category'),
    list: path(ROOTS_DASHBOARD, '/category/list'),
    editById: path(ROOTS_DASHBOARD, `/category/google/edit`),
  },
  ads: {
    root: path(ROOTS_DASHBOARD, '/ads'),
    list: path(ROOTS_DASHBOARD, '/ads/list'),
    newAds: path(ROOTS_DASHBOARD, '/ads/new-ad'),
    editById: path(ROOTS_DASHBOARD, `/ads/google/edit`),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
    general: path(ROOTS_DASHBOARD, '/settings/general'),
    about: path(ROOTS_DASHBOARD, '/settings/about'),
    team: path(ROOTS_DASHBOARD, '/settings/team'),
    newMember: path(ROOTS_DASHBOARD, '/settings/team/new-member'),
    editTeamById: path(ROOTS_DASHBOARD, '/settings/team/member/edit'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
};
