import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';
import { deleteDocument } from '../components/atoms/FirebaseFunctions';

const ACTION = {
  LIST: 'adList',
  AD: 'ad',
  ADD: 'addAd',
  ACTIVE: 'active',
};

const initialState = {
  AdList: [],
  adActiveList: [],
  currentAd: null,
};

const reducer = (state, action) => {
  const { Ads, Ad } = action.payload;
  switch (action.type) {
    case ACTION.LIST:
      return {
        ...state,
        AdList: Ads,
        currentAd: null,
      };
    case ACTION.ADD:
      return {
        ...state,
        AdList: [...state.AdList, Ad],
      };
    case ACTION.AD:
      return {
        ...state,
        currentAd: Ad,
      };
    case ACTION.ACTIVE:
      return {
        ...state,
        adActiveList: Ads,
      };
    default:
      return state;
  }
};

const AdContext = createContext(initialState);

// ----------------------------------------------------------------------
AdProvider.propTypes = {
  children: PropTypes.node,
};

function AdProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const adRef = collection(DB, 'ads');

  useEffect(() => {
    getAllAd();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllAd = async () => {
    const q = query(collection(DB, 'ads'), where('UID', '!=', null));
    const ads = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const ad = doc.data();
      ads.push(ad);
    });
    dispatch({
      type: ACTION.LIST,
      payload: {
        Ads: ads,
      },
    });

    await getActiveAd(ads);
  };

  const uploadAd = async (data) => {
    await addDoc(adRef, data).then(async (docRef) => {
      await updateDoc(docRef, { UID: docRef?.id });
      data.UID = docRef?.id;
    });
    dispatch({
      type: ACTION.ADD,
      payload: {
        Ad: data,
      },
    });
  };

  const editAd = async (data) => {
    await setDoc(doc(DB, 'ads', state.currentAd.UID), data);
    const indexAd = state.AdList.indexOf(state.AdList.find((ad) => ad.UID === state.currentAd.UID));
    const newList = [...state.AdList];
    newList[indexAd] = data;
    dispatch({
      type: ACTION.LIST,
      payload: {
        Ads: newList,
      },
    });
  };

  const deleteAd = async (ad, cover, newList) => {
    deleteDocument(cover);
    await deleteDoc(doc(DB, 'ads', ad));
    dispatch({
      type: ACTION.LIST,
      payload: {
        Ads: newList,
      },
    });
  };

  const deleteOneAd = async (ad) => {
    const docRef = doc(DB, 'ads', ad);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      deleteDocument(docSnap.data().cover);
      await deleteDoc(doc(DB, 'ads', ad));
    }
  };

  const deleteMultiAds = async (ads, newList) => {
    ads.forEach((ad) => {
      deleteOneAd(ad);
    });
    dispatch({
      type: ACTION.LIST,
      payload: {
        Ads: newList,
      },
    });
  };

  const getAd = async (id) => {
    const docRef = doc(DB, 'ads', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.AD,
        payload: {
          Ad: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  const getActiveAd = async (ads) => {
    const filteredAds = [];

    ads.forEach((ad) => {
      if (ad.publish) {
        filteredAds.push({
          id: ad.UID,
          image: ad.cover,
          title: ad.title,
          description: ad.description,
          link: ad.link,
        });
      }
    });

    dispatch({
      type: ACTION.ACTIVE,
      payload: {
        Ads: filteredAds,
      },
    });
  };

  const resetCurrentAd = () => {
    dispatch({
      type: ACTION.AD,
      payload: {
        Ad: null,
      },
    });
  };

  return (
    <AdContext.Provider
      value={{
        ...state,
        method: 'firebase',
        uploadAd,
        getAllAd,
        deleteAd,
        getAd,
        editAd,
        deleteMultiAds,
        resetCurrentAd,
      }}
    >
      {children}
    </AdContext.Provider>
  );
}

export { AdContext, AdProvider };
