/* eslint-disable react/self-closing-comp */
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

const logoWHITE = 'https://firebasestorage.googleapis.com/v0/b/altawheedschool-134f8.appspot.com/o/logo%2Flogo2022_white_tr.png?alt=media&token=c8e1ef58-da5c-4ff4-8513-29dfef610411';
const logoBLACK = 'https://firebasestorage.googleapis.com/v0/b/altawheedschool-134f8.appspot.com/o/logo%2Flogo_black_tr.png?alt=media&token=f467ce7f-fc94-474f-a932-094db41afa73';
const bannerWHITE = 'https://firebasestorage.googleapis.com/v0/b/altawheedschool-134f8.appspot.com/o/logo%2Fbanner-website-white.png?alt=media&token=89add792-1409-4be3-9365-7b6bfc6fea76';
const bannerBLACK = 'https://firebasestorage.googleapis.com/v0/b/altawheedschool-134f8.appspot.com/o/logo%2Fbanner-website-black.png?alt=media&token=83b2e790-9f53-4080-8016-b23b5fef95e1';

export default function Logo({ disabledLink = false, height, isBanner = false }) {
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const isDesktop = useResponsive('up', 'md');

  const link = isDark ? { logo: logoBLACK, banner: bannerBLACK } : { logo: logoWHITE, banner: bannerWHITE }
  const heightVeri = !height ? isDesktop ? '85px' : '50px': height;

  const logo =
    isBanner ?
        <img style={{ width: "auto", height: heightVeri }} viewBox="0, 0, 1, 1" alt="logo" src={link.banner} />
        :
        <img style={{ width: "auto", height: heightVeri }} viewBox="0, 0, 1, 1" alt="logo" src={link.logo} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
