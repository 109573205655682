// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import SvgIconStyle from '../../../components/atoms/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  edition: getIcon('ic_magazin'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  ad: getIcon('ic_ad'),
  category: getIcon('ic_category'),
  settings: getIcon('ic_settings'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'Application', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      // {
      //   title: 'Éditions',
      //   path: PATH_DASHBOARD.edition.root,
      //   icon: ICONS.edition,
      //   children: [
      //     { title: 'Archives', path: PATH_DASHBOARD.edition.list },
      //     { title: 'Nouvelle édition', path: PATH_DASHBOARD.edition.newEdition },
      //   ],
      // },
      // {
      //   title: 'Articles',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'Articles', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'Créer un article', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
      // {
      //   title: 'Catégories',
      //   path: PATH_DASHBOARD.category.root,
      //   icon: ICONS.category,
      //   children: [
      //     { title: 'Liste', path: PATH_DASHBOARD.category.list },
      //     { title: 'Ajouter', path: PATH_DASHBOARD.category.newCategory },
      //   ],
      // },
      {
        title: 'Users',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Users', path: PATH_DASHBOARD.user.list },
          { title: 'Students', path: PATH_DASHBOARD.user.students },
          { title: 'All time students', path: PATH_DASHBOARD.user.allstudents },
          { title: 'Parents', path: PATH_DASHBOARD.user.parents },
          { title: 'Teachers and staff', path: PATH_DASHBOARD.user.teachers },
          { title: 'My account', path: PATH_DASHBOARD.user.account },
        ],
      },
      {
        title: 'Ads',
        path: PATH_DASHBOARD.ads.root,
        icon: ICONS.ad,
        children: [
          { title: 'List', path: PATH_DASHBOARD.ads.list },
          { title: 'Add', path: PATH_DASHBOARD.ads.newAds },
        ],
      },
      {
        title: 'Settings',
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          { title: 'General', path: PATH_DASHBOARD.settings.general },
          { title: 'About us', path: PATH_DASHBOARD.settings.about },
          { title: 'Team', path: PATH_DASHBOARD.settings.team },
        ],
      },
      { title: 'Calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
    ],
  },
];

export default navConfig;
