import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import {
  getAuth,
  signOut,
  updatePassword,
  updateEmail,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { getFirestore, collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

// ---------------------------------------------------------------------

const ACTION = {
  INITIALISE: 'INITIALISE',
  UPDATEUSER: 'UPDATEUSER',
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isAdmin: false
};

const reducer = (state, action) => {
  const { isAuthenticated, user, isAdmin, newinfo } = action.payload;
  switch (action.type) {
    case ACTION.INITIALISE:
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        isAdmin
      };
    case ACTION.UPDATEUSER:
      return {
        ...state,
        user: newinfo,
      };
    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const AUTH = getAuth(firebaseApp);
  const DB = getFirestore(firebaseApp);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);
          const docSnap = await getDoc(userRef);
          let newprofile = null;
          if (docSnap.exists()) {
            newprofile = docSnap.data();
            setProfile(newprofile);
            const roleRef = doc(collection(DB, `${newprofile.role}s`), newprofile.UID);
            const roleSnap = await getDoc(roleRef);
            if (!roleSnap.exists()) {
              await registerToRole(newprofile.UID, newprofile.email, newprofile.firstName, newprofile.lastName, newprofile.phoneNumber, newprofile.role);
            }
          } else {
            const userRef = doc(collection(DB, 'users'), user.uid);
            const docRef = doc(DB, 'students', user.uid);
            let docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
              const docRef = doc(DB, 'teachers', user.uid);
              docSnap = await getDoc(docRef);
            }
            const fetchedData = docSnap.data();

            const data = {
              UID: user.uid,
              firstName: fetchedData.firstName,
              lastName: fetchedData.lastName,
              email: fetchedData.email,
              photoURL: '',
              isActif: false,
              isAdmin: false,
              role: fetchedData.role
            };
            await setDoc(userRef, data).catch((error) => {
              console.log(error);
            });
            await registerToRole(data.UID, data.email, data.firstName, data.lastName, fetchedData.phoneNumber, data.role);
          }
          dispatch({
            type: ACTION.INITIALISE,
            payload: { isAuthenticated: true, user: newprofile, isAdmin: newprofile?.isAdmin },
          });
        } else {
          dispatch({
            type: ACTION.INITIALISE,
            payload: { isAuthenticated: false, user: null, isAdmin: false },
          });
        }
      }),
    [dispatch]
  );

  const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password);

  const forgotPassword = (email) =>
    sendPasswordResetEmail(AUTH, email)
      .then(() => {
        console.log('email sent');
      })
      .catch((error) => {
        console.log(error);
      });

  const changeEmail = (newEmail) =>
    updateEmail(AUTH.currentUser, newEmail)
      .then(() => {
        console.log('changed succesfully');
      })
      .catch(() => 'error');

  const register = (email, password, firstName, lastName, phoneNumber, role) =>
    createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
      const userRef = doc(collection(DB, 'users'), res.user?.uid);
      const data = {
        UID: res.user?.uid,
        firstName,
        lastName,
        email,
        photoURL: '',
        isActif: false,
        isAdmin: false,
        role,
        phoneNumber
      };
      await setDoc(userRef, data).catch((error) => {
        console.log(error);
      });
      await registerToRole(res.user?.uid, email, firstName, lastName, phoneNumber, role)
    });

  const registerToRole = async (UID, email, firstName, lastName, phoneNumber, role) => {
    const objRef = doc(collection(DB, `${role}s`), UID);
    const data = {
      UID,
      firstName,
      lastName,
      email,
      photoURL: '',
      phoneNumber
    };
    if (role === 'teacher') {
      data.classes = [];
    }
    await setDoc(objRef, data).then((arg) => {
      console.log(arg)
    }).catch((error) => {
      console.log(error)
    })
  }

  const updateUser = async (data) => {
    const userRef = doc(collection(DB, 'users'), profile?.UID);
    let isDenied = false;
    if (data?.email !== profile?.email) {
      await changeEmail(data.email).then((value) => {
        if (value === 'error') {
          isDenied = true;
        }
      });
    }
    if (!isDenied) {
      await updateDoc(userRef, data)
        .then(() => {
          const newUser = getNewUserInfo(data);
          dispatch({
            type: ACTION.UPDATEUSER,
            payload: { newinfo: newUser },
          });
        })
        .catch(() => 'error');
    } else {
      return 'log';
    }
  };

  const getNewUserInfo = (newinfo) => ({
    ...state.user,
    firstName: newinfo.firstName,
    lastName: newinfo.lastName,
    photoURL: newinfo.photoURL,
    isActif: newinfo.isActif,
    isAdmin: newinfo.isAdmin,
    role: newinfo.role
  });

  const logout = () => signOut(AUTH).then(() => setProfile(null));

  const changePassword = async (newPassword) => {
    let studentRef;
    let docSnap;
    if (state?.user?.role === "student") {
      studentRef = doc(DB, 'students', state?.user?.UID);
      docSnap = await getDoc(studentRef);
    }
    updatePassword(AUTH.currentUser, newPassword)
      .then(() => {
        if (state?.user?.role === "student") {
          if (docSnap.exists()) {
            let data = docSnap.data();
            data = {
              ...data,
              password: newPassword
            }
            setDoc(studentRef, data);
          }
        }
      })
      .catch(() => 'error');
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          UID: state?.user?.UID,
          firstName: state?.user?.firstName || profile?.firstName || '',
          lastName: state?.user?.lastName || profile?.lastName || '',
          email: state?.user?.email || profile?.email || '',
          photoURL: state?.user?.photoURL || profile?.photoURL || '',
          isActif: state?.user?.isActif || profile?.isActif || '',
          isAdmin: state?.user?.isAdmin || profile?.isAdmin || '',
          role: state?.user?.role || profile?.role || ''
        },
        login,
        register,
        updateUser,
        logout,
        forgotPassword,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
