import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Button, Link, Divider, Container, Typography, Stack, Box } from '@mui/material';
import i18next from 'i18next';
import i18n from '../../locales/i18n';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import Logo from '../../components/atoms/Logo';
import SocialsButton from '../../components/atoms/SocialsButton';


// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: i18n.t("footer.info"),
    children: [
      { name: i18n.t('about.title'), href: PATH_PAGE.about },
      { name: i18n.t('logo.title'), href: PATH_PAGE.logo },
      { name: i18n.t('whatoffer.title'), href: PATH_PAGE.activities },
    ],
  },
  {
    headline: i18n.t('legal.legal'),
    children: [
      { name: i18n.t('legal.term'), href: PATH_PAGE.termsConditions },
    ],
  },
  {
    headline: i18n.t('footer.more'),
    children: [
      { name: i18n.t('contactus.title'), href: PATH_PAGE.contact },
      { name: i18n.t("general.email"), href: 'mailto:altawheed1987@hotmail.com' },
      { name: i18n.t("general.phoneNumber"), href: 'tel:(438)-402-4861' },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

export default function MainFooter() {

  const isArabic = i18next.language === "ar";

  const changeLanguage = () => {
    if (!isArabic) {
      i18next.changeLanguage("ar")
    } else {
      i18next.changeLanguage("en")
    }
    window.location.reload(false);
  }

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'center' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Box display="flex" justifyContent={{ xs: 'center' }} sx={{ pl: { md: 5 } }}>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} isBanner />
            </Box>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" >
              {i18n.t('general.description')}
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
            <Stack
              direction="row"
              justifyContent={{ xs: 'center' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                sx={{ mx: 'auto', textTransform: 'none', fontSize: '5px' }}
                style={{ fontSize: isArabic ? 13 : 16 }}
                component={RouterLink}
                to="#"
                onClick={changeLanguage}
              >
                {i18n.t("general.lng")}
              </Button>
            </Stack>

          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Link
                      to={link.href}
                      onClick={(e) => {
                        if (link.href === 'mailto:altawheed1987@hotmail.com') {
                          window.location.href = 'mailto:altawheed1987@hotmail.com';
                          e.preventDefault();
                        } else if (link.href === 'tel:(438)-402-4861') {
                          window.location.href = 'tel:(438)-402-4861';
                          e.preventDefault();
                        }
                      }}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      component={RouterLink}
                      sx={{ display: 'block', direction: 'ltr' }}
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>

          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © 2022.{' '} {i18n.t('footer.allrightsReserved')}
        </Typography>
      </Container>
    </RootStyle>
  );
}
