import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import { GuestGuard, AuthGuard, RoleBasedGuard, BlogGuard } from '../guards';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/atoms/LoadingScreen';

// ----------------------------------------------------------------------

// const rolesStudent = ['student'];
const rolesTeacher = ['teacher']
const rolesAdmin = ['admin']
const rolesDirector = ['director']


const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: (
            <RoleBasedGuard roles={rolesAdmin}>
              <GeneralApp />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <UserList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'students',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <StudentsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'studentsAll',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <StudentsListAll />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'parents',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <ParentsList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'teachers',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <TeacherList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'student/:name/edit',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <StudentEdit />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'teacher/:name/edit',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <TeacherEdit />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'parent/:name/edit',
              element: (
                <RoleBasedGuard roles={rolesDirector}>
                  <ParentEdit />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'parent/:name',
              element: (
                <RoleBasedGuard roles={rolesTeacher}>
                  <ParentInfo />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <UserCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'mystudents',
              element: (
                <RoleBasedGuard roles={rolesTeacher}>
                  <TeacherStudentList />
                </RoleBasedGuard>
              ),
            },
            // {
            //   path: 'studentInfo/:UID',
            //   element: (
            //     <RoleBasedGuard roles={rolesTeacher}>
            //       <StudentInformation />
            //     </RoleBasedGuard>
            //   ),
            // },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'homeworks',
          children: [
            { element: <Navigate to="/dashboard/homeworks/posts" replace />, index: true },
            {
              path: 'posts',
              element: (
                <RoleBasedGuard roles={rolesTeacher}>
                  <HomeworksList />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'class',
              element: (
                <HomeworksClass />
              ),
            },
            {
              path: 'new-homework',
              element:
                (
                  <RoleBasedGuard roles={rolesTeacher}>
                    <HomeworkNewPost />
                  </RoleBasedGuard>
                )
            },
            {
              path: ':title/edit',
              element: (
                <RoleBasedGuard roles={rolesTeacher}>
                  <BlogGuard>
                    <HomeworkNewPost />
                  </BlogGuard>
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'ads',
          children: [
            { element: <Navigate to="/dashboard/ads/list" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <AdList />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-ad',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <AdNewAd />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':uid/edit',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <AdNewAd />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/settings/general" replace />, index: true },
            {
              path: 'general',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <Settings />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'about',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <SettingsAbout />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'team',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <SettingsTeam />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'team/new-member',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <NewTeamMember />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'team/:uid/edit',
              element: (
                <RoleBasedGuard roles={rolesAdmin}>
                  <NewTeamMember />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'calendar',
          element: (
            <RoleBasedGuard roles={rolesAdmin}>
              <Calendar />
            </RoleBasedGuard>
          ),
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'banned', element: <UserBanned /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'activities', element: <Activities /> },
        { path: 'logo', element: <LogoPage /> },
        { path: 'team', element: <Team /> },
        { path: 'registration', element: <Registration /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'terms-conditions', element: <TermCondition /> },
        { path: 'registration-completed', element: <RegistrationCompleted /> },
        // { path: 'send-blog', element: <SendText /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));

const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const ParentsList = Loadable(lazy(() => import('../pages/dashboard/ParentsList')));
const StudentsList = Loadable(lazy(() => import('../pages/dashboard/StudentsList')));
const StudentsListAll = Loadable(lazy(() => import('../pages/dashboard/StudentsListAll')));
const TeacherList = Loadable(lazy(() => import('../pages/dashboard/TeacherList')));

const StudentEdit = Loadable(lazy(() => import('../pages/dashboard/StudentEdit')));
const TeacherEdit = Loadable(lazy(() => import('../pages/dashboard/TeacherEdit')));
const ParentEdit = Loadable(lazy(() => import('../pages/dashboard/ParentEdit')));
const ParentInfo = Loadable(lazy(() => import('../pages/dashboard/ParentInfo')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const TeacherStudentList = Loadable(lazy(() => import('../pages/dashboard/TeacherStudentList')));
// const StudentInformation = Loadable(lazy(() => import('../pages/dashboard/StudentInformation')));

const HomeworkNewPost = Loadable(lazy(() => import('../pages/dashboard/HomeworkNewPost')));
const HomeworksList = Loadable(lazy(() => import('../pages/dashboard/HomeworksList')));
const HomeworksClass = Loadable(lazy(() => import('../pages/dashboard/HomeworksClass')));

const AdList = Loadable(lazy(() => import('../pages/dashboard/AdList')));
const AdNewAd = Loadable(lazy(() => import('../pages/dashboard/AdNewAd')));

const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')));
const SettingsAbout = Loadable(lazy(() => import('../pages/dashboard/SettingsAbout')));
const SettingsTeam = Loadable(lazy(() => import('../pages/dashboard/SettingsTeam')));
const NewTeamMember = Loadable(lazy(() => import('../pages/dashboard/NewTeamMember')));

const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Activities = Loadable(lazy(() => import('../pages/Activities')));
const LogoPage = Loadable(lazy(() => import('../pages/LogoPage')));
const Team = Loadable(lazy(() => import('../pages/Team')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Registration = Loadable(lazy(() => import('../pages/Registration')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const RegistrationCompleted = Loadable(lazy(() => import('../pages/RegistrationCompleted')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const UserBanned = Loadable(lazy(() => import('../pages/UserBanned')));
const TermCondition = Loadable(lazy(() => import('../pages/TermCondition')));
// const SendText = Loadable(lazy(() => import('../pages/SendText')));
