import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { getFirestore, collection, query, where, addDoc, getDocs, updateDoc } from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  getEventsSuccess: 'getEventsSuccess',
  createEventSuccess: 'createEventSuccess',
};

const initialState = {
  isLoading: false,
  events: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.getEventsSuccess:
      return {
        ...state,
        isLoading: false,
        events: action?.payload?.eventList,
      };
    case ACTION.createEventSuccess:
      return {
        ...state,
        events: [...state.events, action?.payload?.newEvent],
      };
    default:
      return state;
  }
};

const CalendarContext = createContext(initialState);

// ----------------------------------------------------------------------

CalendarProvider.propTypes = {
  children: PropTypes.node,
};

function CalendarProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const [state, dispatch] = useReducer(reducer, initialState);
  const calendarRef = collection(DB, 'calendrier');

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const q = query(collection(DB, 'calendrier'), where('UID', '!=', null));
    const newEntities = [];
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      entity.start = entity.start?.toDate();
      entity.end = entity.end?.toDate();
      newEntities.push(entity);
    });

    dispatch({
      type: ACTION.getEventsSuccess,
      payload: {
        eventList: newEntities,
      },
    });
  };

  const createEvent = async (eventData) => {
    await addDoc(calendarRef, eventData).then(async (docRef) => {
      await updateDoc(docRef, { UID: docRef?.id });
      eventData.UID = docRef?.id;
    });
    dispatch({
      type: ACTION.createEventSuccess,
      payload: {
        newEvent: eventData,
      },
    });
    return eventData.UID;
  };

  return (
    <CalendarContext.Provider
      value={{
        ...state,
        method: 'firebase',
        getEvents,
        createEvent,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}

export { CalendarContext, CalendarProvider };
