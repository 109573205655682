import i18n from '../locales/i18n';

export const SOCIALS = [
    {
        name: 'Facebook',
        icon: 'eva:facebook-fill',
        socialColor: '#1877F2',
        path: i18n.t("general.facebook"),
    },
    {
        name: 'Instagram',
        icon: 'ant-design:instagram-filled',
        socialColor: '#E02D69',
        path: i18n.t("general.instagram"),
    },
    {
        name: 'Youtube',
        icon: 'fa:youtube',
        socialColor: '#FF0000',
        path: i18n.t("general.youtube"),
    },
];


