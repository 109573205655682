import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { getFirestore, arrayUnion, updateDoc, collection, query, where, getDocs, getDoc, doc, deleteDoc, setDoc, arrayRemove } from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';
import { deleteDocument } from '../components/atoms/FirebaseFunctions';
import useAuth from '../hooks/useAuth';


const ACTION = {
  USERS: 'userList',
  USER: 'user',
  STUDENTS: 'students',
  TEACHERS: 'teachers',
  PARENTS: 'parents',
  LOGGEDINUSER: 'login'
};

const initialState = {
  userDefault: null,
  userList: [],
  currentUser: null,
  studentsList: [],
  teachersList: [],
  parentsList: []
};

const reducer = (state, action) => {
  const { users, User } = action.payload;
  let newList = [];
  switch (action.type) {
    case ACTION.USERS:
      return {
        ...state,
        userList: users,
        currentUser: null,
      };
    case ACTION.STUDENTS:
      newList = users.filter(object => {
        return object.lastName !== 'student';
      });
      return {
        ...state,
        studentsList: newList
      };
    case ACTION.TEACHERS:
      return {
        ...state,
        teachersList: users
      };
    case ACTION.PARENTS:
      newList = users.filter(object => {
        return object.parentFirstName1 !== 'demo';
      });
      return {
        ...state,
        parentsList: newList
      };
    case ACTION.USER:
      return {
        ...state,
        currentUser: User,
      };
    case ACTION.LOGGEDINUSER:
      return {
        ...state,
        userDefault: User,
      };
    default:
      return state;
  }
};

const UserContext = createContext(initialState);

// ----------------------------------------------------------------------
UserProvider.propTypes = {
  children: PropTypes.node,
};

function UserProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const { user } = useAuth();

  useEffect(() => {
    getAllUser();
    getAllStudents();
    getAllTeachers();
    getAllParents();
    onLoginUser();
  }, []);

  useEffect(() => {
    onLoginUser();
  }, [user]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllUser = async () => {
    const q = query(collection(DB, 'users'), where('UID', '!=', null));
    const users = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const user = doc.data();
      users.push(user);
    });
    dispatch({
      type: ACTION.USERS,
      payload: {
        users
      },
    });
  };

  const getAllStudents = async () => {
    const q = query(collection(DB, 'students'), where('UID', '!=', null));
    const students = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      students.push(doc.data());
    });
    dispatch({
      type: ACTION.STUDENTS,
      payload: {
        users: students
      },
    });
  };

  const getAllStudentsCurrentYear = async () => {
    const q = query(collection(DB, 'students'), where('UID', '!=', null), where('year', '==', 2024));
    const students = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      students.push(doc.data());
    });
    dispatch({
      type: ACTION.STUDENTS,
      payload: {
        users: students
      },
    });
  };

  const deleteEmptyParent = async () => {
    const q = query(collection(DB, 'parents'), where('childs', '==', []));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const getAllTeachers = async () => {
    const q = query(collection(DB, 'teachers'), where('UID', '!=', null));
    const teachers = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const userTeacher = state.userList.find((user) => user.UID === doc.data().UID);
      teachers.push({
        ...doc.data(),
        photoURL: userTeacher?.photoURL,
        isActif: userTeacher?.isActif,
        phoneNumber: userTeacher?.phoneNumber
      });
    });
    dispatch({
      type: ACTION.TEACHERS,
      payload: {
        users: teachers
      },
    });
  };

  const getAllParents = async () => {
    const q = query(collection(DB, 'parents'), where('UID', '!=', null));
    const parents = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      parents.push(doc.data());
    });
    dispatch({
      type: ACTION.PARENTS,
      payload: {
        users: parents
      },
    });
  };

  const editUser = async (data) => {
    const commonData = {
      firstName: data.firstName,
      lastName: data.lastName,
      photoURL: data.photoURL
    };
    await setDoc(doc(DB, 'users', state.currentUser.UID), data);
    await setCommonData(commonData, `${data.role}s`);
    const indexUser = state.userList.indexOf(state.userList.find((user) => user.UID === state.currentUser.UID));
    const newList = [...state.userList];
    newList[indexUser] = data;
    dispatch({
      type: ACTION.USERS,
      payload: {
        users: newList,
      },
    });
    resetCurrentUser();
  };

  const editStudent = async (data) => {
    const commonData = {
      firstName: data.firstName,
      lastName: data.lastName,
      photoURL: data.photoURL
    };
    await setDoc(doc(DB, 'students', state.currentUser.UID), data);
    await setCommonData(commonData, 'users');
    const indexUser = state.studentsList.indexOf(state.studentsList.find((user) => user.UID === state.currentUser.UID));
    const newList = [...state.studentsList];
    newList[indexUser] = data;
    dispatch({
      type: ACTION.STUDENTS,
      payload: {
        users: newList,
      },
    });
    // await addStudentToClass(state.currentUser.UID, data.classe);
    resetCurrentUser();
  };

  // const addStudentToClass = async (UID, classe) => {
  //   const classRef = doc(DB, 'classes', classe.toString());
  //   await updateDoc(classRef, {
  //     students: arrayUnion(UID)
  //   });
  // }

  const editTeacher = async (data) => {
    const commonData = {
      firstName: data.firstName,
      lastName: data.lastName,
      photoURL: data.photoURL
    };
    await setDoc(doc(DB, 'teachers', state.currentUser.UID), data);
    await setCommonData(commonData, 'users');
    const indexUser = state.teachersList.indexOf(state.teachersList.find((user) => user.UID === state.currentUser.UID));
    const newList = [...state.teachersList];
    newList[indexUser] = data;
    dispatch({
      type: ACTION.TEACHERS,
      payload: {
        users: newList,
      },
    });
    resetCurrentUser();
  };

  const editParent = async (data) => {
    await setDoc(doc(DB, 'parents', state.currentUser.UID), data);
    const indexUser = state.parentsList.indexOf(state.parentsList.find((user) => user.UID === state.currentUser.UID));
    const newList = [...state.parentsList];
    newList[indexUser] = data;
    dispatch({
      type: ACTION.PARENTS,
      payload: {
        users: newList,
      },
    });
    resetCurrentUser();
  };

  const setCommonData = async (commonData, collection) => {
    if (collection !== 'admins' || collection !== 'directors') {
      const docRef = doc(DB, collection, state.currentUser.UID);
      const obj = await getDoc(docRef);
      commonData = {
        ...obj.data(),
        ...commonData
      }
      await setDoc(doc(DB, collection, state.currentUser.UID), commonData);
    }
  };

  const deleteUser = async (user, profilePic, newList, role) => {
    if (role === 'student') {
      await deleteChildFromParent(user);
    }

    deleteDocument(profilePic);
    await deleteDoc(doc(DB, 'users', user));
    await deleteDoc(doc(DB, `${role}s`, user));

    dispatch({
      type: ACTION.USERS,
      payload: {
        users: newList,
      },
    });
  };

  const deleteChildFromParent = async (UID) => {
    const docRef = doc(DB, 'students', UID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const parentsRef = doc(DB, 'parents', docSnap.data().parentId);
      await updateDoc(parentsRef, { childs: arrayRemove(UID) });
    }
  }

  const deleteOneUser = async (user) => {
    const docRef = doc(DB, 'users', user);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if (docSnap.data().role === 'student') {
        await deleteChildFromParent(user);
      }
      deleteDocument(docSnap.data().photoURL);
      await deleteDoc(doc(DB, 'users', user));
      await deleteDoc(doc(DB, `${docSnap.data().role}s`, user));
    }
  };

  const deleteMultiUsers = async (users, newList) => {
    users.forEach((user) => {
      deleteOneUser(user);
    });
    dispatch({
      type: ACTION.USERS,
      payload: {
        users: newList,
      },
    });
  };

  const getUser = async (id) => {
    const docRef = doc(DB, 'users', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let User = docSnap.data();
      if (User.role !== 'admin') {
        const docRef = doc(DB, `${User.role}s`, id);
        const obj = await getDoc(docRef);
        User = {
          ...User,
          ...obj.data()
        }
      }
      dispatch({
        type: ACTION.USER,
        payload: {
          User
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  const onLoginUser = async () => {
    if (user.UID !== undefined && user?.role !== 'admin') {
      const docRef = doc(DB, `${user.role}s`, user.UID);
      const obj = await getDoc(docRef);
      const User = {
        ...user,
        ...obj.data()
      }
      dispatch({
        type: ACTION.LOGGEDINUSER,
        payload: {
          User
        },
      });
    }
  }

  const getUserRole = async (id, role) => {
    if (role !== 'admin') {
      const docRef = doc(DB, `${role}s`, id);
      const obj = await getDoc(docRef);
      const User = {
        ...state.currentUser,
        ...obj.data()
      };
      dispatch({
        type: ACTION.USER,
        payload: {
          User
        },
      });

    }
  }

  const getParent = async (id) => {
    const docRef = doc(DB, 'parents', id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      dispatch({
        type: ACTION.USER,
        payload: {
          User: docSnap.data()
        },
      });
    } else {
      console.log('No such document!');
    }
  }

  const resetCurrentUser = () => {
    dispatch({
      type: ACTION.USER,
      payload: {
        User: null,
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        method: 'firebase',
        getAllUser,
        getAllStudents,
        getAllStudentsCurrentYear,
        getAllParents,
        getAllTeachers,
        getParent,
        deleteUser,
        getUser,
        getUserRole,
        deleteEmptyParent,
        editUser,
        editStudent,
        editParent,
        editTeacher,
        deleteMultiUsers,
        resetCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
