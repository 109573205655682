import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  startAfter,
  orderBy,
  query,
  where,
  limit,
  getDocs,
} from 'firebase/firestore';
//
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  LIST: 'blogList',
  MORE: 'moreblogList',
  JOURNALIST: 'journalist',
};

const initialState = {
  BlogListJournalist: [],
  LastBlog: null,
  disableMoreButton: false,
  journalist: {},
};

const reducer = (state, action) => {
  const { Blogs, LastBlog, disableMoreButton, journalist } = action.payload;
  switch (action.type) {
    case ACTION.LIST:
      return {
        ...state,
        BlogListJournalist: Blogs,
        LastBlog,
        disableMoreButton,
      };
    case ACTION.MORE:
      return {
        ...state,
        BlogListJournalist: [...state.BlogListJournalist, ...Blogs],
        LastBlog,
        disableMoreButton,
      };
    case ACTION.JOURNALIST:
      return {
        ...state,
        journalist,
      };
    default:
      return state;
  }
};

const JournalistContext = createContext(initialState);

// ----------------------------------------------------------------------

JournalistProvider.propTypes = {
  children: PropTypes.node,
};

function JournalistProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const [state, dispatch] = useReducer(reducer, initialState);

  const getBlogs = async (authorUID, nBlogs) => {
    const q = query(collection(DB, 'blogs'), where('author.UID', '==', authorUID), orderBy('createdAt'), limit(nBlogs));
    const Blogs = [];
    const querySnapshot = await getDocs(q);
    const LastBlog = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      Blogs.push(entity);
    });
    const disableMoreButton = Blogs.length < 4;

    dispatch({
      type: ACTION.LIST,
      payload: {
        Blogs,
        LastBlog,
        disableMoreButton,
      },
    });
  };

  const getMoreBlogs = async (authorUID, nBlogs) => {
    const q = query(
      collection(DB, 'blogs'),
      where('author.UID', '==', authorUID),
      orderBy('createdAt'),
      startAfter(state.LastBlog),
      limit(nBlogs)
    );

    const Blogs = [];
    const querySnapshot = await getDocs(q);
    const LastBlog = querySnapshot.docs[querySnapshot.docs.length - 1];
    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      Blogs.push(entity);
    });
    const disableMoreButton = Blogs.length < 4;
    dispatch({
      type: ACTION.MORE,
      payload: {
        Blogs,
        LastBlog,
        disableMoreButton,
      },
    });
  };

  const getJournalist = async (UID) => {
    const docRef = doc(DB, 'users', UID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      dispatch({
        type: ACTION.JOURNALIST,
        payload: {
          journalist: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  return (
    <JournalistContext.Provider
      value={{
        ...state,
        method: 'firebase',
        getBlogs,
        getMoreBlogs,
        getJournalist,
      }}
    >
      {children}
    </JournalistContext.Provider>
  );
}

export { JournalistContext, JournalistProvider };
