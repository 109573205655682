// Config
import { PATH_AFTER_LOGIN } from '../../config';
// components
import Iconify from '../../components/atoms/Iconify';
import i18n from '../../locales/i18n';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: i18n.t('home.home'),
    icon: <Iconify icon={'bxs:home'} {...ICON_SIZE} />,
    path: '/',
  },
  {
    title: i18n.t('registration.title'),
    icon: <Iconify icon={'ic:round-app-registration'} {...ICON_SIZE} />,
    path: '/registration',
  },
  {
    title: i18n.t('dashboard.dashboard'),
    icon: <Iconify icon={'healthicons:ui-user-profile'} {...ICON_SIZE} />,
    path: PATH_AFTER_LOGIN,
  },
];

export default menuConfig;
