import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { Box } from '@mui/material';
import i18next from 'i18next';
import { varFade } from './variants';

// ----------------------------------------------------------------------

TextAnimate.propTypes = {
  text: PropTypes.string.isRequired,
  variants: PropTypes.object,
  sx: PropTypes.object,
};

export default function TextAnimate({ text, variants, sx, ...other }) {

  const isArabic = i18next.language === "ar";

  return (
    <Box
      component={m.h1}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >

      {isArabic ?
        <m.span variants={variants || varFade().inUp}>
          {text}
        </m.span>
        :
        <span>
          {
            text.split('').map((letter, index) => (
              <m.span key={index} variants={variants || varFade().inUp}>
                {letter}
              </m.span>
            ))
          }
        </span>
      }

    </Box>
  );
}
