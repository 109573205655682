// react-ga
// import ReactGA from 'react-ga4';
// routes
import { FunctionsProvider, useFirebaseApp } from 'reactfire';
import { getFunctions } from 'firebase/functions';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import RtlLayout from './components/atoms/RtlLayout';
import { ChartStyle } from './components/organismes/chart';
import ScrollToTop from './components/atoms/ScrollToTop';
import { ProgressBarStyle } from './components/atoms/ProgressBar';
import NotistackProvider from './components/atoms/NotistackProvider';
import ThemeColorPresets from './components/atoms/ThemeColorPresets';
import ThemeLocalization from './components/atoms/ThemeLocalization';
import MotionLazyContainer from './components/molecules/animate/MotionLazyContainer';
import { BlogProvider } from './contexts/BlogContext';
import { AdProvider } from './contexts/AdContext';
import { HomeProvider } from './contexts/HomeContext';
import { StudentProvider } from './contexts/StudentContext';
import { UserProvider } from './contexts/UserContext';
import { AboutProvider } from './contexts/AboutContext';
import { RegistrationProvider } from './contexts/RegistrationContext';
import { TeamProvider } from './contexts/TeamContext';
import { HomeworkProvider } from './contexts/HomeworkContext';
import { CalendarProvider } from './contexts/CalendarContext';
import { JournalistProvider } from './contexts/JournalistContext';
import { GeneralSettingsProvider } from './contexts/GeneralSettingsContext';
// import { GoogleAnalyticsHelperProvider } from './contexts/GoogleAnalyticsHelperContext';
// import { DashboardAnalyticsProvider } from './contexts/DashboardAnalyticsContext';

// ----------------------------------------------------------------------

export default function App() {
  // set up google analytics
  // const { REACT_APP_GA4_MEASUREMENT_ID } = process.env;

  // ReactGA.initialize(REACT_APP_GA4_MEASUREMENT_ID);

  const firebaseApp = useFirebaseApp();

  // const location = useLocation();

  // sends a pageview to google analytics whenever the url changes
  // useEffect(() => {
  //   ReactGA.send({ hitType: 'pageview', page: location.pathname });
  // }, [location]);

  return (
    <FunctionsProvider sdk={getFunctions(firebaseApp)}>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <GeneralSettingsProvider>
                  <HomeProvider>
                    <RegistrationProvider>
                      {/* <GoogleAnalyticsHelperProvider> */}
                      {/* <DashboardAnalyticsProvider> */}
                      <UserProvider>
                        <StudentProvider>
                          <CalendarProvider>
                            <JournalistProvider>
                              <BlogProvider>
                                <HomeworkProvider>
                                  <AdProvider>
                                    <AboutProvider>
                                      <TeamProvider>
                                        <MotionLazyContainer>
                                          <ProgressBarStyle />
                                          <ChartStyle />
                                          <ScrollToTop />
                                          <Router />
                                        </MotionLazyContainer>
                                      </TeamProvider>
                                    </AboutProvider>
                                  </AdProvider>
                                </HomeworkProvider>
                              </BlogProvider>
                            </JournalistProvider>
                          </CalendarProvider>
                        </StudentProvider>
                      </UserProvider>
                      {/* </DashboardAnalyticsProvider> */}
                      {/* </GoogleAnalyticsHelperProvider> */}
                    </RegistrationProvider>
                  </HomeProvider>
                </GeneralSettingsProvider>
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemeColorPresets>
      </ThemeProvider>
    </FunctionsProvider>
  );
}
