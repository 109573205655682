import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  getDocs,
  getDoc,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
} from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';
import { deleteDocument } from '../components/atoms/FirebaseFunctions';

const ACTION = {
  MEMBERLIST: 'memberList',
  CURRENTMEMBER: 'member',
  ADD: 'addMember',
  INFO: 'info',
};

const initialState = {
  memberList: [],
  currentMember: null,
  title: '',
  subtitle: '',
};

const PATH_MEMBERS_DB = 'settings/team/members';

const reducer = (state, action) => {
  const { members, member, info } = action.payload;
  let list = [];
  switch (action.type) {
    case ACTION.MEMBERLIST:
      members.sort((member1, member2) => member1.order - member2.order);
      return {
        ...state,
        memberList: members,
        currentMember: null,
      };
    case ACTION.ADD:
      list = [...state.memberList, member];
      list.sort((member1, member2) => member1.order - member2.order);
      return {
        ...state,
        memberList: list,
      };
    case ACTION.CURRENTMEMBER:
      return {
        ...state,
        currentMember: member,
      };
    case ACTION.INFO:
      return {
        ...state,
        title: info.title,
        subtitle: info.subtitle,
      };
    default:
      return state;
  }
};

const TeamContext = createContext(initialState);

// ----------------------------------------------------------------------
TeamProvider.propTypes = {
  children: PropTypes.node,
};

function TeamProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const membersRef = collection(DB, PATH_MEMBERS_DB);

  useEffect(() => {
    getAllMembers();
    getInformations();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllMembers = async () => {
    const q = query(collection(DB, PATH_MEMBERS_DB), where('UID', '!=', null));
    const members = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const member = doc.data();
      members.push(member);
    });
    dispatch({
      type: ACTION.MEMBERLIST,
      payload: {
        members,
      },
    });
  };

  const addMember = async (data) => {
    await addDoc(membersRef, data).then(async (docRef) => {
      await updateDoc(docRef, { UID: docRef?.id });
      data.UID = docRef?.id;
    });
    dispatch({
      type: ACTION.ADD,
      payload: {
        member: data,
      },
    });
  };

  const editMember = async (data) => {
    await setDoc(doc(DB, PATH_MEMBERS_DB, state.currentMember.UID), data);
    const indexMember = state.memberList.indexOf(
      state.memberList.find((member) => member.UID === state.currentMember.UID)
    );
    const newList = [...state.memberList];
    newList[indexMember] = data;
    dispatch({
      type: ACTION.MEMBERLIST,
      payload: {
        members: newList,
      },
    });
  };

  const editInformations = async (data) => {
    await setDoc(doc(DB, 'settings', 'team'), data);
    dispatch({
      type: ACTION.INFO,
      payload: {
        info: data,
      },
    });
  };

  const getInformations = async () => {
    const docRef = doc(DB, 'settings', 'team');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.INFO,
        payload: {
          info: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  const deleteMember = async (memberUID, picture, newList) => {
    deleteDocument(picture);
    await deleteDoc(doc(DB, PATH_MEMBERS_DB, memberUID));
    dispatch({
      type: ACTION.MEMBERLIST,
      payload: {
        members: newList,
      },
    });
  };

  const deleteOneMember = async (member) => {
    const docRef = doc(DB, PATH_MEMBERS_DB, member);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      deleteDocument(docSnap.data().picture);
      await deleteDoc(doc(DB, PATH_MEMBERS_DB, member));
    }
  };

  const deleteMultiMembers = async (members, newList) => {
    members.forEach((member) => {
      deleteOneMember(member);
    });
    dispatch({
      type: ACTION.MEMBERLIST,
      payload: {
        members: newList,
      },
    });
  };

  const getMember = async (id) => {
    const docRef = doc(DB, PATH_MEMBERS_DB, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.CURRENTMEMBER,
        payload: {
          member: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  const resetCurrentMember = () => {
    dispatch({
      type: ACTION.CURRENTMEMBER,
      payload: {
        member: null,
      },
    });
  };

  return (
    <TeamContext.Provider
      value={{
        ...state,
        method: 'firebase',
        addMember,
        getAllMembers,
        deleteMember,
        getMember,
        editMember,
        deleteMultiMembers,
        resetCurrentMember,
        editInformations,
        getInformations,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
}

export { TeamContext, TeamProvider };
