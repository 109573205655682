import React, { useContext } from 'react';

import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import { HomeworkContext } from '../contexts/HomeworkContext';
// ----------------------------------------------------------------------

BlogGuard.propTypes = {
  children: PropTypes.node
};

export default function BlogGuard({ children }) {
  const { isAuthenticated, user, isAdmin } = useAuth();
  const { currentHomework } = useContext(HomeworkContext);

  if (!isAuthenticated) {
    return <Navigate to="/404" replace />;
  }

  // console.log(currentHomework)
  if (!isAdmin && currentHomework && currentHomework?.author !== user.UID) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
