// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import SvgIconStyle from '../../../components/atoms/SvgIconStyle';
import i18n from '../../../locales/i18n';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  edition: getIcon('ic_magazin'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  ad: getIcon('ic_ad'),
  category: getIcon('ic_category'),
  settings: getIcon('ic_settings'),
  homeworks: getIcon('ic_edit_square')
};

const navConfigStudent = [
  {
    subheader: i18n.t('dashboard.myaccount'),
    items: [
      {
        title: i18n.t('dashboard.myaccount'),
        path: PATH_DASHBOARD.user.account,
        icon: ICONS.user,
      },
      {
        title: i18n.t('homework.homeworks'),
        path: PATH_DASHBOARD.homeworks.class,
        icon: ICONS.homeworks,
      }

    ],
  },
];

export default navConfigStudent;
