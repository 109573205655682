import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { getFirestore, getDoc, doc, setDoc } from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  ABOUT: 'ABOUT',
};

const initialState = {
  cover: '',
  pic2: '',
  slogan: '',
  text: '',
  vision: '',
};

const reducer = (state, action) => {
  const { data } = action.payload;
  switch (action.type) {
    case ACTION.ABOUT:
      return data;
    default:
      return state;
  }
};

const AboutContext = createContext(initialState);

// ----------------------------------------------------------------------
AboutProvider.propTypes = {
  children: PropTypes.node,
};

function AboutProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);

  const [state, dispatch] = useReducer(reducer, initialState);

  const editAbout = async (data) => {
    await setDoc(doc(DB, 'settings', 'about'), data);
    dispatch({
      type: ACTION.ABOUT,
      payload: {
        data,
      },
    });
  };

  const getAbout = async () => {
    const docRef = doc(DB, 'settings', 'about');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.ABOUT,
        payload: {
          data: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  useEffect(() => {
    getAbout();
  }, []);

  return (
    <AboutContext.Provider
      value={{
        ...state,
        method: 'firebase',
        editAbout,
        getAbout,
      }}
    >
      {children}
    </AboutContext.Provider>
  );
}

export { AboutContext, AboutProvider };
