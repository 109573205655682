import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  roles: PropTypes.array
};

export default function RoleBasedGuard({ children, roles }) {
  const { isAdmin, user } = useAuth();
  if (!isAdmin && roles &&  !roles.includes(user.role)) {
    return <Navigate to="/404" replace />;
  }

  return <>{children}</>;
}
