import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { getFirestore, getDoc, doc, setDoc } from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  INFO: 'info',
  TERM_CONDITION: 'termCondition',
};

const initialState = {
  code: '',
  isActive: false,
  termCondition: '',
};

const reducer = (state, action) => {
  const { info } = action.payload;
  switch (action.type) {
    case ACTION.INFO:
      return { ...state, code: info.code, isActive: info.isActive };
    case ACTION.TERM_CONDITION:
      return { ...state, termCondition: info };
    default:
      return state;
  }
};

const GeneralSettingsContext = createContext(initialState);

// ----------------------------------------------------------------------
GeneralSettingsProvider.propTypes = {
  children: PropTypes.node,
};

function GeneralSettingsProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);

  useEffect(() => {
    getInformations();
    // getTermCondition();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const editInformations = async (data) => {
    await setDoc(doc(DB, 'settings', 'verifyCodeUser'), data);
    dispatch({
      type: ACTION.INFO,
      payload: {
        info: data,
      },
    });
  };

  const getInformations = async () => {
    const docRef = doc(DB, 'settings', 'verifyCodeUser');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.INFO,
        payload: {
          info: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
  };

  const isVerifyCodeValid = (userCode) => {
    if (!state.isActive) {
      return 'nonActive';
    }
    if (userCode !== state.code) {
      return 'invalid';
    }
    return 'success';
  };

  return (
    <GeneralSettingsContext.Provider
      value={{
        ...state,
        method: 'firebase',
        editInformations,
        getInformations,
        isVerifyCodeValid,
      }}
    >
      {children}
    </GeneralSettingsContext.Provider>
  );
}

export { GeneralSettingsContext, GeneralSettingsProvider };
