import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Link } from '@mui/material';
import i18next from 'i18next';

// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import useSettings from '../../hooks/useSettings';
// utils
import cssStyles from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// components
import Logo from '../../components/atoms/Logo';
import DarkModeSwitch from '../../components/atoms/DarkModeSwitch';

// menu
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import i18n from '../../locales/i18n';
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();
  const { themeMode, onToggleMode } = useSettings();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');
  const isSm = useResponsive('up', 'sm');

  const isHome = pathname === '/';

  const changeLanguage = () => {
    if (i18next.language === "en") {
      i18next.changeLanguage("ar")
    } else {
      i18next.changeLanguage("en")
    }
    window.location.reload(false);
  }

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo isBanner/>
          <Box sx={{ flexGrow: 1 }} />
          {isDesktop && <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
          {
            isSm &&
            <Link
              style={{ color: theme.palette.text.primary }}
              to="#"
              key="ar"
              color="inherit"
              variant="body2"
              component={RouterLink}
              sx={{ display: 'block', pr: 2 }}
              onClick={changeLanguage}
            >
              {i18n.t("general.lng-small")}
            </Link>
          }
          <DarkModeSwitch sx={{ m: { xs: 0, sm: 1 } }} defaultChecked value={themeMode} onChange={onToggleMode} />

          {!isDesktop && <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
