// routes
import { PATH_DASHBOARD } from '../../../routes/paths';

// components
import SvgIconStyle from '../../../components/atoms/SvgIconStyle';
import i18n from '../../../locales/i18n';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  edition: getIcon('ic_magazin'),
  user: getIcon('ic_user'),
  calendar: getIcon('ic_calendar'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  ad: getIcon('ic_ad'),
  category: getIcon('ic_category'),
  settings: getIcon('ic_settings'),
  homeworks: getIcon('ic_edit_square')
};

const navConfigTeacher = [
  {
    subheader: i18n.t('dashboard.myaccount'),
    items: [
      {
        title: i18n.t('dashboard.users.users'),
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: i18n.t('dashboard.myaccount'), path: PATH_DASHBOARD.user.account },
          { title: i18n.t('dashboard.students.students'), path: PATH_DASHBOARD.user.mystudents },
        ],
      },
      {
        title: i18n.t('homework.homeworks'),
        path: PATH_DASHBOARD.homeworks.root,
        icon: ICONS.homeworks,
        children: [
          { title: i18n.t('dashboard.homework.list'), path: PATH_DASHBOARD.homeworks.posts },
          { title: i18n.t('dashboard.homework.new'), path: PATH_DASHBOARD.homeworks.newPost },
        ],
      }
    ],
  },
];

export default navConfigTeacher;
