import PropTypes from 'prop-types';
import { createContext, useReducer } from 'react';
import {
  getFirestore,
  collection,
  query,
  where,
  addDoc,
  getDocs,
  setDoc,
  getDoc,
  updateDoc,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

import { useSnackbar } from 'notistack';
import i18n from '../locales/i18n';

const ACTION = {
  LIST: 'blogList',
  ADD: 'addBlog',
  GET: 'getOneBlog',
};

const initialState = {
  BlogList: [],
  CategoryList: [],
  currentBlog: null,
};

const reducer = (state, action) => {
  const { Blogs, Blog } = action.payload;
  switch (action.type) {
    case ACTION.LIST:
      return {
        ...state,
        BlogList: Blogs,
      };
    case ACTION.CATEGList:
      return {
        ...state,
        CategoryList: Blogs,
      };
    case ACTION.ADD:
      return {
        ...state,
        BlogList: [...state.BlogList, Blog],
      };
    case ACTION.GET:
      return {
        ...state,
        currentBlog: Blog,
      };
    default:
      return state;
  }
};

const BlogContext = createContext(initialState);

// ----------------------------------------------------------------------

BlogProvider.propTypes = {
  children: PropTypes.node,
};

function BlogProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const [state, dispatch] = useReducer(reducer, initialState);
  const blogRef = collection(DB, 'blogs');

  // useEffect(() => {
  //   getAllBlogs();
  //   getBlogsByCategory(categoryActiveList[0]?.title);
  // }, [categoryActiveList]);

  // const getAllBlogs = async () => {
  //   const q = query(collection(DB, 'blogs'), where('UID', '!=', null));
  //   const newEntities = [];
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     const entity = doc.data();
  //     newEntities.push(entity);
  //   });

  //   dispatch({
  //     type: ACTION.LIST,
  //     payload: {
  //       Blogs: newEntities,
  //     },
  //   });
  // };

  const getBlogsByCategory = async (category) => {
    const q = query(collection(DB, 'blogs'), where('category', '==', category));
    const newEntities = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const entity = doc.data();
      newEntities.push(entity);
    });
    dispatch({
      type: ACTION.CATEGList,
      payload: {
        Blogs: newEntities,
      },
    });
  };

  const getOneBlog = async (id) => {
    const docRef = doc(DB, 'blogs', id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      dispatch({
        type: ACTION.GET,
        payload: {
          Blog: docSnap.data(),
        },
      });
    } else {
      console.log('No such document!');
    }
    return docSnap.data();
  };

  const uploadBlog = async (data) => {
    await addDoc(blogRef, data).then(async (docRef) => {
      await updateDoc(docRef, { UID: docRef?.id });
      // eslint-disable-next-line dot-notation
      data['UID'] = docRef?.id;
    });
    dispatch({
      type: ACTION.ADD,
      payload: {
        Blog: data,
      },
    });
  };

  const deleteBlog = async (UID) => {
    await deleteDoc(doc(DB, 'blogs', UID)).then(() => {
      const deletedBlog = state.BlogList.filter((blog) => blog.UID !== UID);
      enqueueSnackbar(i18n.t('blogPost.articleDelete'));
      dispatch({
        type: ACTION.LIST,
        payload: {
          Blogs: deletedBlog,
        },
      });
    });
  };

  const editBlog = async (data) => {
    await setDoc(doc(DB, 'blogs', state.currentBlog.UID), data);
    const indexBlog = state.BlogList.indexOf(state.BlogList.find((blog) => blog.UID === state.currentBlog.UID));
    const newList = [...state.BlogList];
    newList[indexBlog] = data;
    dispatch({
      type: ACTION.LIST,
      payload: {
        Blogs: newList,
      },
    });
  };

  return (
    <BlogContext.Provider
      value={{
        ...state,
        method: 'firebase',
        uploadBlog,
        getOneBlog,
        deleteBlog,
        editBlog,
        getBlogsByCategory,
      }}
    >
      {children}
    </BlogContext.Provider>
  );
}

export { BlogContext, BlogProvider };
