import { deleteObject, getStorage, ref } from 'firebase/storage';

// const DEFAULT_PICTURE =
//   'https://firebasestorage.googleapis.com/v0/b/polyscope-6feba.appspot.com/o/team-image%2Ftmp.jpg?alt=media&token=d400f96f-06fa-4b5f-ae71-25be65f2a56b';

export async function deleteDocument(ressource) {
  if (ressource !== "") {
    const BASEURL = 'https://firebasestorage.googleapis.com/v0/b/altawheedschool-134f8.appspot.com/o/';
    let imagePath = ressource.replace(BASEURL, '');
    const indexOfEndPath = imagePath.indexOf('?');
    imagePath = imagePath.substring(0, indexOfEndPath).replace('%2F', '/').replace(/%20/g, ' ');

    const storage = getStorage();
    const imgRef = ref(storage, imagePath);

    deleteObject(imgRef)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  }
}

export const deletePreviousDrop = async (array) => {
  for (let i = 0; i < array.length - 1; i += 1) {
    deleteDocument(array[i]);
  }
};
