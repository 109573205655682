import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import {
  getFirestore,
  collection,
  getDoc,
  doc,
} from 'firebase/firestore';
import { useFirebaseApp } from 'reactfire';

const ACTION = {
  LIST: 'pictureList',
  VIDEO: 'video',
  OFFER: 'offer'
  // AD: 'ad',
  // ADD: 'addAd',
  // ACTIVE: 'active',
};

const initialState = {
  pictureList: [],
  videos: {},
  offer: {}
  // adActiveList: [],
  // currentAd: null,
};

const reducer = (state, action) => {
  const { pictureList, videos, offer } = action.payload;
  switch (action.type) {
    case ACTION.LIST:
      return {
        ...state,
        pictureList
        // currentAd: null,
      };
    case ACTION.VIDEO:
      return {
        ...state,
        videos
        // currentAd: null,
      };
    case ACTION.OFFER:
      return {
        ...state,
        offer
        // currentAd: null,
      };
    // case ACTION.ADD:
    //   return {
    //     ...state,
    //     AdList: [...state.AdList, Ad],
    //   };
    // case ACTION.AD:
    //   return {
    //     ...state,
    //     currentAd: Ad,
    //   };
    // case ACTION.ACTIVE:
    //   return {
    //     ...state,
    //     adActiveList: Ads,
    //   };
    default:
      return state;
  }
};

const HomeContext = createContext(initialState);

// ----------------------------------------------------------------------
HomeProvider.propTypes = {
  children: PropTypes.node,
};

function HomeProvider({ children }) {
  const firebaseApp = useFirebaseApp();
  const DB = getFirestore(firebaseApp);
  const homeRef = collection(DB, 'home');

  useEffect(() => {
    getAllPictures();
    getAllVideos();
    getAllOffer();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const getAllPictures = async () => {
    const docRef = doc(homeRef, 'pictures');
    const pictureList = [];
    const querySnapshot = await getDoc(docRef);
    if (querySnapshot.exists()) {
      const pic = querySnapshot.data();
      for (let i = 1; i < 8; i += 1) {
        pictureList.push(pic[`picture${i}`]);
      }
      dispatch({
        type: ACTION.LIST,
        payload: {
          pictureList,
        },
      });
    }


    // await getActiveAd(ads);
  };

  const getAllVideos = async () => {
    const docRef = doc(homeRef, 'video');
    const querySnapshot = await getDoc(docRef);
    if (querySnapshot.exists()) {
      const videos = querySnapshot.data();
      dispatch({
        type: ACTION.VIDEO,
        payload: {
          videos
        },
      });
    }
  };

  const getAllOffer = async () => {
    const docRef = doc(homeRef, 'offer');
    const querySnapshot = await getDoc(docRef);
    if (querySnapshot.exists()) {
      const offer = querySnapshot.data();
      dispatch({
        type: ACTION.OFFER,
        payload: {
          offer
        },
      });
    }
  };

  // const uploadAd = async (data) => {
  //   await addDoc(adRef, data).then(async (docRef) => {
  //     await updateDoc(docRef, { UID: docRef?.id });
  //     data.UID = docRef?.id;
  //   });
  //   dispatch({
  //     type: ACTION.ADD,
  //     payload: {
  //       Ad: data,
  //     },
  //   });
  // };

  // const editAd = async (data) => {
  //   await setDoc(doc(DB, 'ads', state.currentAd.UID), data);
  //   const indexAd = state.AdList.indexOf(state.AdList.find((ad) => ad.UID === state.currentAd.UID));
  //   const newList = [...state.AdList];
  //   newList[indexAd] = data;
  //   dispatch({
  //     type: ACTION.LIST,
  //     payload: {
  //       Ads: newList,
  //     },
  //   });
  // };

  // const deleteAd = async (ad, cover, newList) => {
  //   deleteDocument(cover);
  //   await deleteDoc(doc(DB, 'ads', ad));
  //   dispatch({
  //     type: ACTION.LIST,
  //     payload: {
  //       Ads: newList,
  //     },
  //   });
  // };

  // const deleteOneAd = async (ad) => {
  //   const docRef = doc(DB, 'ads', ad);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     deleteDocument(docSnap.data().cover);
  //     await deleteDoc(doc(DB, 'ads', ad));
  //   }
  // };

  // const deleteMultiAds = async (ads, newList) => {
  //   ads.forEach((ad) => {
  //     deleteOneAd(ad);
  //   });
  //   dispatch({
  //     type: ACTION.LIST,
  //     payload: {
  //       Ads: newList,
  //     },
  //   });
  // };

  // const getAd = async (id) => {
  //   const docRef = doc(DB, 'ads', id);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //     dispatch({
  //       type: ACTION.AD,
  //       payload: {
  //         Ad: docSnap.data(),
  //       },
  //     });
  //   } else {
  //     console.log('No such document!');
  //   }
  // };

  // const getActiveAd = async (ads) => {
  //   const filteredAds = [];

  //   ads.forEach((ad) => {
  //     if (ad.publish) {
  //       filteredAds.push({
  //         id: ad.UID,
  //         image: ad.cover,
  //         title: ad.title,
  //         description: ad.description,
  //         link: ad.link,
  //       });
  //     }
  //   });

  //   dispatch({
  //     type: ACTION.ACTIVE,
  //     payload: {
  //       Ads: filteredAds,
  //     },
  //   });
  // };

  // const resetCurrentAd = () => {
  //   dispatch({
  //     type: ACTION.AD,
  //     payload: {
  //       Ad: null,
  //     },
  //   });
  // };

  return (
    <HomeContext.Provider
      value={{
        ...state,
        method: 'firebase',
        // uploadAd,
        getAllPictures,
        getAllVideos,
        getAllOffer
        // deleteAd,
        // getAd,
        // editAd,
        // deleteMultiAds,
        // resetCurrentAd,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
}

export { HomeContext, HomeProvider };
